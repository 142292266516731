import { useState } from 'react';
import EventConfig from '../config/events.json';

function EventsPage(){

    const capitalise = s => s && s[0].toUpperCase() + s.slice(1);

    const EventForms = EventConfig.map(form => {
        return <EventForm 
                    title={form.title ? form.title : capitalise(form.eventName)}
                    eventName={form.eventName}
                    fields={form.fields}
                    callback={submitEventForm}
                />
    });

    return(
        <>
            <h1>Submit Events</h1>
            {EventForms}
        </>
    )
}

function EventForm(props){

    const [fields, setFields] = useState(props.fields);

    function fieldCallback(e, name) {
        var newVal = e.target.value;
        var _fields = [...fields];
        _fields.forEach(field=> {
            if (field.name === name) {
                field.value = newVal;
            }
        });
        setFields(fields);
    }

    const fieldMap = fields.map(field => {
        return <div>{field.name} <input defaultValue={field.default} onChange={(e) => (fieldCallback(e, field.name))}></input></div>
    });

    return(
        <div className='event-form'>
            <h2>{props.title}</h2>
            {fieldMap}
            <button onClick={()=>(props.callback(props.eventName, fields))}>Create a {props.title}</button>
        </div>
    )
}

function submitEventForm(eventName, values) {
    if (window.exponea) {
        var payload = {};
        for (let value of values) {
            payload[value.name] = value.value;
        }
        window.exponea.track(eventName, payload);
    }
    else {
        //throw error
    }
}

export default EventsPage;