import logo from '../img/brlogo.png';
import { Link } from 'react-router-dom';

function Header(){

    return (
        <div className='header'>
            <div><Link to='/'><img src={logo} className='logo-main' alt="logo" /></Link></div>
            <div className='header-link'><Link to='/events'>Events</Link></div>
        </div>
    )

}

export default Header;