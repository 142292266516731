import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';

import HomePage from './pages/Home';
import EventsPage from './pages/Events';
import GiftFinderPOC from './pages/GiftFinderPOC';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className='main-content'>
          <Routes>
              <Route path='/' Component={HomePage} />
              <Route path='/events' Component={EventsPage} />
              <Route path='/gift-finder' Component={GiftFinderPOC} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
